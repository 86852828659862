import axios from "axios"
import { createContext, useContext, useEffect, useMemo, useState } from "react"
import FeedBackBox from "../components/global/FeedBackBox"
import { URL } from "../config/apis"
import useAuth from "./Auth"

function getWindowSize() {
  const { innerWidth } = window
  return innerWidth
}

const allowed = ["632af64f0a9b3176f08341dc", "6329fa69783d7e09911a23bc"]
const orgs = [
  "64139c5d180cbbcc4a3535bb",
  "646bb68d8a96b766adf29a18",
  "64930b3dec7eb903d7f72817",
  "64d62b9eac9ff5fd48066ca5",
  "63ffa55d916cb4df2753acd2"
]

const DataContext = createContext()

export const DataContextProvider = ({ children }) => {
  const { user } = useAuth()
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [err, setErr] = useState(null)
  const [dealerships, setDealerships] = useState([])
  const [windowSize, setWindowSize] = useState(getWindowSize())

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener("resize", handleWindowResize)

    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])

  const getDealerships = async () => {
    try {
      const res = await axios.get(`${URL}/dealerships/all`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      setDealerships(res.data)
    } catch (error) {
      console.log(error.reaponse?.data?.message || error.message)
    }
  }

  const disabled = useMemo(() => {
    if (orgs.includes(user?.selected?.organisation)) {
      return false
    } else if (
      !user?.dealerships?.map((d) => d?._id)?.includes(user?.selected?._id)
    ) {
      return true
    } else {
      return false
    }
  }, [user])

  useEffect(() => {
    if (user) {
      getDealerships()
    }
  }, [user])

  const memoedValue = useMemo(
    () => ({
      loading,
      setLoading,
      show,
      setShow,
      err,
      setErr,
      windowSize,
      dealerships,
      disabled,
    }),
    [loading, show, err, windowSize, dealerships, disabled]
  )

  return (
    <DataContext.Provider value={memoedValue}>
      <>{children}</>
      <>
        <FeedBackBox loading={loading} show={show} err={err} />
      </>
    </DataContext.Provider>
  )
}

export default function useData() {
  return useContext(DataContext)
}
