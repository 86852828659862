import { Box, Stack } from "@mui/material"
import React from "react"

export default function AppointmentsActions({ setLeads, lead, setProgress }) {
  return (
    <>
         <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "15% !important",
          px: 5,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack spacing={2}>Accepted</Stack>
      </Box>
    </>
  )
}
