import { Chip, Divider, Paper, Stack, Typography } from "@mui/material"
import React from "react"
import useAuth from "../../context/Auth"
import { formatPhoneNumber } from "../../helppers/formatters"

export default function UserDetails() {
  const { user } = useAuth()
  return (
    <Paper component={Stack} flex={{ xs: 1, lg: 2 }}>
      <Stack alignItems={"center"} py={3} px={2} spacing={2}>
        {/* image */}
        <Paper
          style={{
            height: 100,
            width: 100,
            position: "relative",
            borderRadius: 50,
            overflow: "hidden",
          }}
          component={"div"}
          variant="outlined"
        >
          <img
            src={
              user?.avatar?.url ||
              "https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg"
            }
            alt={`${user?.fname}`}
            style={{ height: "100px", width: "100px", objectFit: "cover" }}
          />
        </Paper>
        <Divider flexItem />
        <Stack alignItems={'center'} spacing={1}>
          {/* full name */}
          <Typography variant="h5" fontWeight={'600'}>{user?.fname}</Typography>
          {/* email */}
          <Typography variant="body1">{user?.email}</Typography>
          {/* phone */}
          <Typography variant="body1">{formatPhoneNumber(user?.phone)}</Typography>
        </Stack>
        <Divider flexItem />
        {/* dealerships */}
        <Stack spacing={1} sx={{ width: "100%" }}>
          {user?.dealerships &&
            user?.dealerships?.map((d) => (
              <Chip
                sx={{ width: "100%", borderRadius: 1, fontWeight: "500" }}
                key={d?._id}
                color={d?._id === user?.selected?._id ? "success" : "default"}
                label={d?.dname}
                size="medium"
                variant="outlined"
              />
            ))}
        </Stack>
        {/* super admin */}
        {user?.supperAdmin && (
          <>
            <Divider flexItem />
            <Chip
              sx={{ width: "100%", borderRadius: 1, fontWeight: "500" }}
              label="Super-Admin"
              size="medium"
              color="primary"
            />
          </>
        )}
      </Stack>
    </Paper>
  )
}
