import * as React from "react"

import Chip from "@mui/material/Chip"
import DoneIcon from "@mui/icons-material/Done"
import ErrorIcon from "@mui/icons-material/Error"
import PopUpDialog from "../PopUpDialog"
import CfxData from "./CfxData"

export default function CarfaxBox({ lead }) {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <Chip
        label="CarFax"
        sx={{
          px: 0.5,
          py: 0.5,
          fontSize: { xs: "0.7125rem", md: "0.8125rem" },
        }}
        size="small"
        deleteIcon={lead?.isCleanCfx ? <DoneIcon /> : <ErrorIcon />}
        color={lead?.isCleanCfx ? "success" : "error"}
        onDelete={() => {}}
        onClick={() => setOpen(true)}
      />
      <PopUpDialog title="CarFax" open={open} setOpen={setOpen}>
        {open && <CfxData data={lead?.carfax_data} isClean={lead?.isCleanCfx} />}
      </PopUpDialog>
    </>
  )
}
