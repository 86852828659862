import * as React from "react"
import CssBaseline from "@mui/material/CssBaseline"
import Stack from "@mui/material/Stack"
import Container from "@mui/material/Container"
import Snackbar from "@mui/material/Snackbar"
import LeadCard from "../components/leadCard"

import axios from "axios"
import { URL } from "../config/apis"
import { useEffect } from "react"
import LoadingPage from "../components/global/LoadingPage"
import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Pagination,
  Typography,
} from "@mui/material"
import ErrorState from "../components/global/ErrorState"
import SearchIcon from "@mui/icons-material/Search"
import RestartAltIcon from "@mui/icons-material/RestartAlt"

const pageSize = 20

export default function MyBidsPage() {
  const cash = JSON.parse(sessionStorage.getItem("mybids"))

  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(true)
  const [leads, setLeads] = React.useState([])
  const [err, setErr] = React.useState(null)
  const [count, setCount] = React.useState(0)
  const [vin, setVin] = React.useState("")

  const [page, setPage] = React.useState(cash?.page || 1)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const getLeads = async (vin) => {
    setErr(null)
    setIsLoading(true)
    try {
      const res = await axios.get(
        `${URL}/leads/my-bids?page=${page}&vin=${vin}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      setLeads(res.data.bids)
      setCount(res.data.count)
    } catch (error) {
      setErr(error.response?.data?.message || error.message)
    }
    setLoading(false)
    setIsLoading(false)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const totalPages = React.useMemo(() => {
    return Math.ceil(count / pageSize)
  }, [count, pageSize])

  useEffect(() => {
    getLeads(vin)

    sessionStorage.setItem("mybids", JSON.stringify({ page }))
  }, [page])

  if (loading || isLoading) {
    return <LoadingPage />
  }
  if (err) {
    return <ErrorState message={err} />
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        maxWidth="xl"
        sx={{
          flex: 1,
          height: "100%",
          flexDirection: "column",
          display: "flex",
          py: 2,
        }}
      >
        <Stack
          sx={{ width: "100%" }}
          justifyContent={"space-between"}
          direction={"row"}
          alignItems={"center"}
          pb={2}
          flexWrap={"wrap"}
          gap={1}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <FormControl
              size="small"
              sx={{
                width: { xs: "25ch", md: "30ch" },
              }}
              variant="outlined"
            >
              <InputLabel
                sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
                htmlFor="search-by-vin"
              >
                Search By VIN
              </InputLabel>
              <OutlinedInput
                id="search-by-vin"
                onChange={({ target }) => setVin(target?.value?.toUpperCase())}
                value={vin?.toUpperCase()}
                sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Search By VIN"
                      onClick={() => {
                        setPage(1)
                        getLeads(vin)
                      }}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      disabled={vin?.trim()?.length < 4}
                      // disabled={vin?.trim()?.length !== 17}
                      size="small"
                    >
                      {isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <SearchIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Search By VIN"
              />
            </FormControl>
            <Button
              size="medium"
              color="error"
              variant="outlined"
              disabled={isLoading}
              startIcon={<RestartAltIcon />}
              onClick={() => {
                setPage(1)
                setVin("")
                getLeads("")
              }}
            >
              Reset
            </Button>
          </Stack>
          {count !== null && count > 0 && (
            <Typography fontWeight={"600"}>
              {pageSize * (page - 1) + leads?.length} / {count}
            </Typography>
          )}
        </Stack>
        <Divider flexItem />
        <Stack sx={{ width: "100%", mt: 1 }} spacing={2}>
          {leads?.length > 0 && !err ? (
            <>
              {leads?.map((lead, i) => (
                <LeadCard
                  setOpen={setOpen}
                  lead={lead}
                  key={lead?._id + i}
                  setLeads={setLeads}
                  type="counter"
                  getLeads={getLeads}
                />
              ))}
            </>
          ) : leads?.length === 0 ? (
            <>no leads found for the moment</>
          ) : (
            err && <>{err}</>
          )}
        </Stack>
        <br />
        {totalPages > 1 && (
          <Stack
            direction={"row"}
            justifyContent="center"
            sx={{ mt: 2 }}
            spacing={2}
          >
            <Pagination
              page={page}
              onChange={handleChangePage}
              count={totalPages}
              showFirstButton
              showLastButton
            />
          </Stack>
        )}
      </Container>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        // onClose={handleClose}
        message="Copied"
      />
    </React.Fragment>
  )
}
