import * as React from "react"
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import CssBaseline from "@mui/material/CssBaseline"

import ResponssiveAppBar from "../navs/ResponssiveAppBar"
import { DataContextProvider } from "../../context/Data"
import { Alert } from "@mui/material"
import useAuth from "../../context/Auth"
import AgreementModel from "./AgreementModel"

export const GlobalLayoutContext = React.createContext(null)

const MainLayout = ({ children }) => {
  const { user } = useAuth()

  const [open, setOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <DataContextProvider>
      <GlobalLayoutContext.Provider
        value={{
          handleDrawerOpen,
          handleDrawerClose,
          open,
        }}
      >
        {user?.selected?.isAgreed || user?.supperAdmin ? (
          <Box
            sx={{
              display: "flex",
              backgroundColor: "#FAFAFA",
              minHeight: "100vh",
              flexDirection: "column",
            }}
          >
            <ResponssiveAppBar
              handleDrawerOpen={handleDrawerOpen}
              open={open}
              handleDrawerClos={handleDrawerClose}
            />
            <Box
              component="main"
              sx={{ py: 2, flex: 1, display: "flex", flexDirection: "column" }}
            >
              {user?.supperAdmin && <Alert />}
              {!user?.supperAdmin && user?.isMulti && <Alert />}
              <Toolbar />
              {children}
            </Box>
          </Box>
        ) : (
          <AgreementModel />
        )}
      </GlobalLayoutContext.Provider>
    </DataContextProvider>
  )
}
export default MainLayout
