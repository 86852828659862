import { Alert, Button, Stack, TextField } from "@mui/material"
import axios from "axios"
import React from "react"
import { URL } from "../../config/apis"

const TextFieldInput = ({ label, value, handleChange }) => {
  return (
    <TextField
      size="small"
      id="outlined-basic"
      label={label}
      variant="outlined"
      fullWidth
      value={value}
      onChange={(e) => handleChange(e.target.value)}
      type="password"
    />
  )
}

export default function PasswordEdit() {
  const [current, setCurrent] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [confirm, setConfirm] = React.useState("")

  const [loading, setLoading] = React.useState(false)
  const [success, setSucess] = React.useState(null)
  const [error, setError] = React.useState(null)

  const submitUpdate = async () => {
    if (!confirm || !current || !password) {
      setError("Please fill all requires fields")
      return
    }
    if (confirm.length < 8 || password.length < 8) {
      setError("Password must be at least 8 characters long")
      return
    }
    if (confirm !== password) {
      setError("New Password and Confirm password must match")
      return
    }

    setError(null)
    setSucess(null)
    setLoading(true)
    try {
      const res = await axios.put(
        `${URL}/users/reset`,
        { password, current, confirm },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      if (res.data.ok) {
        setLoading(false)
        setSucess("Password Updated succesfully")
        setConfirm('')
        setPassword('')
        setCurrent('')
      }
    } catch (error) {
      setError(error.response?.data?.message || error.message)
      setLoading(false)
      console.log(error)
    } finally {
      setTimeout(() => {
        setSucess(null)
      }, 2000)
    }
  }

  return (
    <Stack px={2} pt={3} pb={2} spacing={3}>
      <TextFieldInput
        label="Current Password"
        value={current}
        handleChange={(val) => setCurrent(val)}
        name="current"
      />
      <TextFieldInput
        label="New Password"
        value={password}
        handleChange={(val) => setPassword(val)}
        name="password"
      />
      <TextFieldInput
        label="Confirm Password"
        value={confirm}
        handleChange={(val) => setConfirm(val)}
        name="confirm"
      />

      <br />
      <Stack direction={"row"} justifyContent="end">
        <Button
          onClick={submitUpdate}
          disabled={loading}
          variant="contained"
          color="primary"
        >
          Update Password
        </Button>
      </Stack>
      {loading && <Alert severity="info">Updating Please wait ...</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
    </Stack>
  )
}
