import * as React from "react"

import Chip from "@mui/material/Chip"
import PopUpDialog from "../PopUpDialog"

export default function ConditionReport({ condition }) {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      <Chip
        label="Condition Report"
        sx={{
          px: 0.5,
          py: 0.5,
          fontSize: { xs: "0.7125rem", md: "0.8125rem" },
        }}
        size="small"
        color="primary"
        onClick={() => setOpen(true)}
      />
      <PopUpDialog title="Condition Report" open={open} setOpen={setOpen}>
        <div dangerouslySetInnerHTML={{ __html: condition?.html }}></div>
      </PopUpDialog>
    </>
  )
}
