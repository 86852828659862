import * as React from "react"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import Typography from "@mui/material/Typography"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { Stack, styled, useTheme } from "@mui/material"
import { appName } from "../../constants/constants"
import { GlobalLayoutContext } from "../layouts/MainLayout"
import { NavLink } from "react-router-dom"

const drawerWidth = 240
const navItems = [
  { label: "Leads", href: "/leads" },
  // { label: "Interested", href: "/interested" },
  { label: "Counters", href: "/counters" },
  { label: "My Bids", href: "/my-bids" },
  { label: "Accepted", href: "/accepted" },
  { label: "Appointments", href: "/appointments" },
  { label: "Done Deals", href: "/deals" },
  { label: "Denied", href: "/denied" },
]

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}))

export default function SideBarResponssive({ container }) {
  const theme = useTheme()
  const { handleDrawerOpen, open, handleDrawerClose } =
    React.useContext(GlobalLayoutContext)
  return (
    <Box component="nav">
      <Drawer
        container={container}
        variant="temporary"
        open={open}
        onClose={handleDrawerOpen}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        <DrawerHeader>
          {/* <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              fontWeight: 700,
              color: "inherit",
              width: "100%",
            }}
            paddingX={2}
          >
            {appName}
          </Typography> */}
          <Stack
            flex={1}
            position={"relative"}
          >
            <img
              src="/dark-logo.png"
              alt="DealerBids"
              style={{ height: 45, width: "auto", objectFit: "contain" }}
            />
          </Stack>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box sx={{ textAlign: "center" }}>
          <List>
            {navItems.map((item, i) => (
              <ListItem
                onClick={handleDrawerClose}
                key={item.href + i}
                disablePadding
              >
                <NavLink
                  to={item.href}
                  style={({ isActive }) => ({
                    color: isActive ? "black" : "inherit",
                    textDecoration: "none",
                    width: "100%",
                  })}
                >
                  {({ isActive }) => (
                    <ListItemButton
                      sx={{
                        backgroundColor: isActive ? "#00000026" : "inherit",
                      }}
                    >
                      <ListItemText primary={item.label} />
                    </ListItemButton>
                  )}
                </NavLink>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  )
}
