import * as React from "react"
import CssBaseline from "@mui/material/CssBaseline"
import Stack from "@mui/material/Stack"
import Container from "@mui/material/Container"
import Paper from "@mui/material/Paper"
import Snackbar from "@mui/material/Snackbar"
import LeadCard from "../components/leadCard"

import axios from "axios"
import { URL } from "../config/apis"
import { useEffect } from "react"
import LoadingPage from "../components/global/LoadingPage"
import { Divider, Pagination } from "@mui/material"
import ErrorState from "../components/global/ErrorState"

const pageSize = 20

export default function AcceptedPage() {
  const cash = JSON.parse(sessionStorage.getItem("accepted"))

  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(true)
  const [leads, setLeads] = React.useState([])
  const [losts, setLosts] = React.useState([])
  const [err, setErr] = React.useState(null)

  const [count, setCount] = React.useState(0)

  const [page, setPage] = React.useState(cash?.page || 1)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const getLeads = async () => {
    setErr(null)
    setIsLoading(true)
    try {
      const res = await axios.get(`${URL}/leads/accepted?page=${page}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      setLeads(res.data.leads)
      setCount(res.data.count)
    } catch (error) {
      setErr(error.response?.data?.message || error.message)
    }
    setLoading(false)
    setIsLoading(false)
  }
  const totalPages = React.useMemo(() => {
    return Math.ceil(count / pageSize)
  }, [count, pageSize])

  useEffect(() => {
    getLeads()
    sessionStorage.setItem("accepted", JSON.stringify({ page }))
  }, [page])

  if (loading || isLoading) {
    return <LoadingPage />
  }

  if (err) {
    return <ErrorState message={err} />
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        maxWidth="xl"
        sx={{
          flex: 1,
          height: "100%",
          flexDirection: "column",
          display: "flex",
          py: 2,
        }}
      >
        <Stack sx={{ width: "100%" }} spacing={2}>
          {!loading && !err && leads?.length === 0 ? (
            <>NO DEALS ACCEPTED YET!</>
          ) : (
            <>
              {leads?.map((lead, i) => (
                <LeadCard
                  setOpen={setOpen}
                  lead={lead}
                  key={lead?._id + i}
                  setLeads={setLeads}
                  type="accepted"
                  getLeads={getLeads}
                />
              ))}
            </>
          )}
          {losts?.length > 0 && <Divider />}
          {losts?.map((lead, i) => (
            <LeadCard
              setOpen={setOpen}
              lead={lead}
              key={lead?._id + i}
              setLeads={setLeads}
              type="accepted"
              getLeads={getLeads}
            />
          ))}
        </Stack>
        <br />
        {totalPages > 1 && (
          <Stack
            direction={"row"}
            justifyContent="center"
            sx={{ mt: 2 }}
            spacing={2}
          >
            <Pagination
              page={page}
              onChange={handleChangePage}
              count={totalPages}
              showFirstButton
              showLastButton
            />
          </Stack>
        )}
      </Container>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        // onClose={handleClose}
        message="Copied"
      />
    </React.Fragment>
  )
}
