import { Container, Typography } from "@mui/material"
import React from "react"

export default function ErrorState({ message }) {
  return <Container>
    <Typography variant="h6" color={'gray'}>

    {message}
    </Typography>
    </Container>
}
