import React from "react"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"

export default function LineGrid({ lable, value, title }) {
  return (
    <Stack flex={"none"}>
      <Typography
        // color="#7F8FA4"
        fontSize={{ xs: "13px", md: "15px" }}
        fontWeight={500}
        component="legend"
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
        title={title || lable}
      >
        {lable}
      </Typography>
      <Typography
        color="#334150"
        fontSize="15px"
        fontWeight={400}
        component="legend"
      >
        {value}
      </Typography>
    </Stack>
  )
}
