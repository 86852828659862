import * as React from "react"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import Stack from "@mui/material/Stack"
import LineGrid from "./LineGrid"
import { formatPrice } from "../../helppers/formatters"
import Chip from "@mui/material/Chip"
import { Tooltip } from "@mui/material"
import Actions from "./Actions"
import InfoIcon from "@mui/icons-material/Info"
import AcceptedActions from "./AcceptedActions"
import CounterActions from "./CounterActions"
import AppointmentsActions from "./AppointmentsActions"
import DeniedActions from "./DeniedActions"
import { LazyLoadImage } from "react-lazy-load-image-component"
import AutoCheckBox from "../global/autocheck/AutoCheckBox"
import CarfaxBox from "../global/cfx/CarfaxBox"
import Lightbox from "react-18-image-lightbox"
import "react-18-image-lightbox/style.css"
import useData from "../../context/Data"
import ConditionReport from "../global/condition/ConditionReport"

export default function LeadCard({
  setOpen,
  lead,
  setLeads,
  type = "new",
  getLeads,
  extra = false,
}) {
  const { windowSize } = useData()
  const [isCopied, setIsCopied] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [photoIndex, setPhotoIndex] = React.useState(0)

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand("copy", true, text)
    }
  }

  const handleCopyClick = (text) => {
    copyTextToClipboard(text)
      .then(() => {
        setIsCopied(true)
        setOpen(true)
        setTimeout(() => {
          setIsCopied(false)
          setOpen(false)
        }, 1500)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const images = React.useMemo(() => {
    return [lead?.f_image?.url, ...lead?.images?.map((x) => x?.url)]
  }, [lead])

  const lost = React.useMemo(() => {
    if (lead?.isLost) return true
    if (lead?.lead?.isTrash) return true
    return false
  }, [lead?.isLost, lead?.isTrash])

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          opacity: loading ? 0.6 : type === "accepted" && lost ? 0.7 : 1,
          bgcolor: extra ? "#2196f378" : "white",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <CardMedia className="cmedia" sx={{ position: "relative" }}>
          <LazyLoadImage
            src={lead?.f_image?.url}
            style={{
              objectFit: "cover",
              objectPosition: "center",
              width: windowSize <= 1000 ? "100%" : "300px",
              flex: "none",
              cursor: "pointer",
            }}
            height="230px"
            width={windowSize <= 1000 ? "100%" : "300px"}
            alt={lead?.title}
            placeholderSrc="/placeholder.png"
          />
          <span
            onClick={() => setIsOpen(true)}
            style={{
              position: "absolute",
              inset: 0,
              justifyContent: "center",
              alignItems: "center",
            }}
            className="showImg"
          >
            {" "}
            Click for more images
          </span>
          <Stack
            display={{ xs: "flex", md: "none" }}
            position="absolute"
            alignItems={"center"}
            sx={{ bottom: 0, right: 0, bgcolor: "#39386e", color: "#fff" }}
            direction="row"
            spacing={1}
            px={2}
            py={0.5}
          >
            <Typography sx={{ flex: "none" }} component="div" variant="body2">
              {type === "accepted" ? "Agreed Price" : "Sellers Price"}:
            </Typography>
            <Typography
              sx={{ flex: "none" }}
              component="div"
              variant="body1"
              fontWeight={700}
            >
              {type === "accepted"
                ? formatPrice(lead?.agreedPrice)
                : formatPrice(lead?.listedPrice)}
            </Typography>
          </Stack>
        </CardMedia>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            gap: 2,
            justifyContent: "center",
          }}
        >
          <CardContent sx={{ flex: "1 0 auto", position: "relative" }}>
            <Stack spacing={2}>
              <Stack
                spacing={5}
                direction={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  fontWeight={600}
                  sx={{ flexGrow: 1 }}
                  component="div"
                  variant={
                    windowSize <= 500
                      ? "body1"
                      : windowSize <= 1000
                      ? "h6"
                      : "h5"
                  }
                >
                  {lead?.title}
                </Typography>
                <Stack display={{ xs: "none", md: "flex" }} alignItems={"end"}>
                  <Typography
                    sx={{ flex: "none" }}
                    component="div"
                    variant="h5"
                    fontWeight={700}
                  >
                    {type === "counter" && lead?.lastCounter?.amount
                      ? formatPrice(lead?.lastCounter?.amount)
                      : type === "accepted"
                      ? formatPrice(lead?.agreedPrice)
                      : formatPrice(lead?.listedPrice)}
                  </Typography>
                  <Typography
                    sx={{ flex: "none" }}
                    component="div"
                    variant="body2"
                  >
                    {type === "counter" && lead?.lastCounter?.amount
                      ? "New Sellers Price"
                      : type === "accepted"
                      ? "Agreed Price"
                      : "Sellers Price"}
                  </Typography>
                </Stack>
              </Stack>
              <Stack spacing={2}>
                <Stack flexWrap={"wrap"} direction="row" gap={1}>
                  <Chip
                    label={lead?.vin}
                    sx={{
                      px: 0.5,
                      py: 0.5,
                      fontSize: { xs: "0.7125rem", md: "0.8125rem" },
                    }}
                    size="small"
                    color={isCopied ? "success" : "primary"}
                    onClick={(e) => handleCopyClick(e.currentTarget.innerText)}
                  />
                  <Chip
                    label={`${lead?.mileage?.toLocaleString()} mi`}
                    sx={{
                      px: 0.5,
                      py: 0.5,
                      fontSize: { xs: "0.7125rem", md: "0.8125rem" },
                    }}
                    size="small"
                    color="primary"
                  />
                  <Tooltip title={lead?.transmission?.full} placement="top">
                    <Chip
                      label={
                        lead?.transmission?.short || lead?.transmission?.full
                      }
                      sx={{
                        px: 0.5,
                        py: 0.5,
                        fontSize: { xs: "0.7125rem", md: "0.8125rem" },
                      }}
                      size="small"
                      deleteIcon={<InfoIcon />}
                      onDelete={() => {}}
                      color="primary"
                    />
                  </Tooltip>
                  <Tooltip title={lead?.engine?.full} placement="top-end">
                    <Chip
                      label={lead?.engine?.short || lead?.engine?.full}
                      sx={{
                        px: 0.5,
                        py: 0.5,
                        fontSize: { xs: "0.7125rem", md: "0.8125rem" },
                      }}
                      size="small"
                      deleteIcon={<InfoIcon color="00000075" />}
                      onDelete={() => {}}
                      color="primary"
                    />
                  </Tooltip>
                </Stack>
                <Stack
                  direction={"row"}
                  //justifyContent="space-between"
                  alignItems={"center"}
                  spacing={{ xs: 2, lg: 5 }}
                >
                  {/* <LineGrid lable="Sellers Price" value={formatPrice(lead?.listedPrice)} /> */}
                  <LineGrid lable="MSRP" value={formatPrice(lead?.msrp)} />

                  {/* <LineGrid
                    lable="Listed Price"
                    value={formatPrice(lead?.listedPrice)}
                  /> */}
                  <LineGrid lable="MMR" value={formatPrice(lead?.mmr)} />
                  {lead?.kbbppv && (
                    <LineGrid
                      lable={windowSize <= 500 ? "KBB PP" : "KBB Private Party"}
                      value={formatPrice(lead?.lead?.kbbppv)}
                      title="KBB Private Party"
                    />
                  )}
                  {/* <LineGrid
                    lable={windowSize <= 500 ? "MDS" : "Market Day Supply"}
                    value={lead?.mds || "N/A"}
                  />  */}
                  {/* <LineGrid lable="Median DOM" value={lead?.mdom || "N/A"} />*/}
                </Stack>
                <Stack flexWrap={"wrap"} direction="row" gap={1}>
                  {lead?.carfax_data && <CarfaxBox lead={lead} />}
                  {/* <AutoCheckBox lead={lead} /> */}

                  {lead?.titleStatus && (
                    <Chip
                      label={lead?.titleStatus}
                      sx={{
                        px: 0.5,
                        py: 0.5,
                        fontSize: { xs: "0.7125rem", md: "0.8125rem" },
                      }}
                      size="small"
                      color="primary"
                    />
                  )}
                  {lead?.condition?.html && (
                    <ConditionReport condition={lead?.condition} />
                  )}
                </Stack>
              </Stack>
            </Stack>
            {type === "accepted" && lost && (
              <div className="sold">
                <img src="/lost.png" style={{ height: "90%", width: "auto" }} />
              </div>
            )}
          </CardContent>
        </Box>
        <Divider
          orientation={windowSize <= 1000 ? "horizontal" : "vertical"}
          variant="middle"
          flexItem
        />
        {type === "new" || type === "interested" ? (
          <Actions
            lead={lead}
            setLeads={setLeads}
            setProgress={setLoading}
            getLeads={getLeads}
            type={type}
          />
        ) : type === "accepted" ? (
          <AcceptedActions
            lead={lead}
            setLeads={setLeads}
            setProgress={setLoading}
            getLeads={getLeads}
          />
        ) : type === "counter" ? (
          <CounterActions
            lead={lead}
            setLeads={setLeads}
            setProgress={setLoading}
            getLeads={getLeads}
          />
        ) : type === "appointment" ? (
          <AppointmentsActions
            lead={lead}
            setLeads={setLeads}
            setProgress={setLoading}
          />
        ) : (
          type === "denied" && (
            <DeniedActions
              lead={lead}
              setLeads={setLeads}
              setProgress={setLoading}
              getLeads={getLeads}
            />
          )
        )}
      </Card>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((prev) => (prev + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((prev) => (prev + 1) % images.length)
          }
          style={{ zIndex: 9999999999 }}
        />
      )}
    </>
  )
}
