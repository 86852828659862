import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material"
import React from "react"

export default function PricesInput({
  lable,
  value,
  sign = "$",
  onValueChange,
  disabled = false,
  ...props
}) {
  return (
    <FormControl disabled={disabled} {...props} size="small">
      <InputLabel htmlFor="outlined-adornment-amount">{lable}</InputLabel>
      <OutlinedInput
        id="outlined-adornment-amount"
        value={
          value == null ? null : isNaN(value) ? null : value?.toLocaleString()
        }
        onChange={(e) =>
          onValueChange(parseFloat((e.target.value || "0").replace(/,/g, "")))
        }
        startAdornment={
          <InputAdornment position="start">{sign}</InputAdornment>
        }
        label={lable}
      />
    </FormControl>
  )
}

export function PricesInputSimple({ lable, value, sign = "$", onValueChange }) {
  return (
    <FormControl sx={{ width: "100%" }} size="small">
      <InputLabel htmlFor="outlined-adornment-amount">{lable}</InputLabel>
      <OutlinedInput
        id="outlined-adornment-amount"
        value={value == null ? null : value?.toLocaleString()}
        onChange={(e) =>
          onValueChange(parseFloat((e.target.value || "0").replace(/,/g, "")))
        }
        startAdornment={
          <InputAdornment position="start">{sign}</InputAdornment>
        }
        label={lable}
      />
    </FormControl>
  )
}
