import * as React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import ListItemText from "@mui/material/ListItemText"
import ListItem from "@mui/material/ListItem"
import List from "@mui/material/List"
import Divider from "@mui/material/Divider"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import CloseIcon from "@mui/icons-material/Close"
import Slide from "@mui/material/Slide"
import { alpha, styled } from "@mui/material/styles"
import { Container, InputBase, Stack } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import { useLocation, useNavigate } from "react-router-dom"
import SectionBox from "./SectionBox"
import AppointmentTable from "../appointments/AppointmentTable"

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "100%",
  },
}))

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function SearchDialog({
  counters,
  myBids,
  accepted,
  loading,
  err,
  query,
  setQuery,
  isDone,
  getResults,
  setCounters,
  setAccepted,
  setMyBids,
  appointments,
  setAppointments,
}) {
  const [open, setOpen] = React.useState(true)
  const { state } = useLocation()

  const navigate = useNavigate()
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    if (state?.pathname) {
      navigate(state?.pathname)
    } else {
      navigate("/")
    }

    setOpen(false)
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      sx={{ zIndex: 999 }}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar sx={{ paddingRight: 0 }}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              value={query}
              onChange={({ target }) => setQuery(target.value)}
              sx={{ width: "100%" }}
            />
          </Search>
          <Stack
            direction={"row"}
            spacing={{ xs: 1, sm: 2 }}
            alignItems="center"
            ml={{ md: 5 }}
          >
            <Button
              color="success"
              variant="contained"
              onClick={getResults}
              disabled={query?.trim() === "" || loading}
            >
              Search
            </Button>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </Toolbar>
      </AppBar>

      {loading ? (
        <Stack
          sx={{
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            px: { xs: 2, md: 0 },
          }}
        >
          <Typography variant="h5" fontWeight={700} color="#ababab">
            Loading ...
          </Typography>
        </Stack>
      ) : !isDone ? (
        <Stack
          sx={{
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            px: { xs: 2, md: 0 },
          }}
        >
          <Typography variant="h5" fontWeight={700} color="#ababab">
            Please type a VIN or Year Make Model to start the search
          </Typography>
        </Stack>
      ) : err ? (
        <Stack
          sx={{
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            px: { xs: 2, md: 0 },
          }}
        >
          <Typography variant="h6" fontWeight={500} color="red">
            {err}
          </Typography>
        </Stack>
      ) : (
        <>
          {accepted?.length === 0 &&
          counters?.length === 0 &&
          myBids?.length === 0 ? (
            <Stack
              sx={{
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                px: { xs: 2, md: 0 },
              }}
            >
              <Typography variant="h5" fontWeight={700} color="#ababab">
                No Results found
              </Typography>
            </Stack>
          ) : (
            <Stack justifyContent={"center"} alignItems="center" spacing={2}>
              {counters?.length > 0 && (
                <SectionBox
                  data={counters}
                  getResults={getResults}
                  setLeads={setCounters}
                  type="counter"
                  title="Counters"
                />
              )}
              {myBids?.length > 0 && (
                <SectionBox
                  data={myBids}
                  getResults={getResults}
                  setLeads={setMyBids}
                  type="counter"
                  title="My Bids"
                />
              )}
              {accepted?.length > 0 && (
                <SectionBox
                  data={accepted}
                  getResults={getResults}
                  setLeads={setAccepted}
                  type="accepted"
                  title="Accepted"
                />
              )}
              {appointments?.length > 0 && (
                <Container
                  maxWidth="xl"
                  sx={{
                    flex: 1,
                    height: "100%",
                    flexDirection: "column",
                    display: "flex",
                    py: 2,
                  }}
                >
                  <Divider className="dp-divider">Appointments</Divider>
                  <br />
                  <AppointmentTable appointments={appointments} />
                  <br />
                </Container>
              )}
            </Stack>
          )}
        </>
      )}
    </Dialog>
  )
}
