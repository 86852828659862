import { useState } from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import "./App.css"
import MainLayout from "./components/layouts/MainLayout"
import { PrivateRoutes } from "./components/layouts/PrivateRoutes"
import useAuth from "./context/Auth"
import NotFound from "./NotFound"
import AcceptedPage from "./pages/AcceptedPage"
import AppointmentsPage from "./pages/AppointmentsPage"
import CountersPage from "./pages/CountersPage"
import Dealerships from "./pages/Dealerships"
import DealsPage from "./pages/DealsPage"
import DeniedPage from "./pages/DeniedPage"
import InterestedPage from "./pages/InterestedPage"
import Login from "./pages/Login"
import MainPage from "./pages/MainPage"
import MyBidsPage from "./pages/MyBidsPage"
import PasswordEdit from "./pages/profile/PasswordEdit"
import ProfileInfoPage from "./pages/profile/ProfileInfoPage"
import ProfilePage from "./pages/ProfilePage"
import SearchPage from "./pages/SearchPage"

function App() {
  const { user, selected } = useAuth()

  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route
          path="/"
          element={
            user?.isOwner && !selected ? (
              <Dealerships />
            ) : (
              <Navigate to={"/leads"} />
            )
          }
        />
        <Route path="/dealerships" element={<Dealerships />} />
        <Route path="/leads" element={<MainPage />} />
        {/* <Route path="/interested" element={<InterestedPage />} /> */}
        <Route path="/accepted" element={<AcceptedPage />} />
        <Route path="/my-bids" element={<MyBidsPage />} />
        <Route path="/counters" element={<CountersPage />} />
        <Route path="/countered" element={<Navigate to={"/my-bids"} />} />
        <Route path="/appointments" element={<AppointmentsPage />} />
        <Route path="/denied" element={<DeniedPage />} />
        <Route path="/deals" element={<DealsPage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/profile" element={<ProfilePage />}>
          <Route index element={<ProfileInfoPage />} />
          <Route path="password" element={<PasswordEdit />} />
        </Route>
        <Route path="/*" element={<NotFound />} />
      </Route>
      <Route
        path="/login"
        element={!user ? <Login /> : <Navigate to={"/"} />}
      />
    </Routes>
  )
}

export default App
