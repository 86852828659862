import React from "react"
import Chip from "@mui/material/Chip"
import Stack from "@mui/material/Stack"

export default function CfxData({ data, isClean }) {
  // const [loading, setLoading] = React.useState(false)
  // const [data, setData] = React.useState(null)
  // const [err, setErr] = React.useState(false)

  // const getData = async () => {
  //   if (!vin || vin?.length !== 17) {
  //     return
  //   }
  //   setData(null)
  //   setLoading(true)
  //   try {
  //     const res = await axios.get(`${URL}/get-api-data/carfax/${vin}`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       withCredentials: true,
  //     })
  //     const d = res.data
  //     setLoading(false)
  //     if (!d?.success) {
  //       setErr("Something went wrong!")
  //       return
  //     }

  //     setData(d)
  //   } catch (error) {
  //     setLoading(false)
  //     setErr(error.response?.data?.message || error.message)
  //   }
  // }

  // useEffect(() => {
  //   getData()
  // }, [vin])

  return (
    <Stack direction={"row"} gap={2} flexWrap="wrap">
      {data && (
        <>
          <Chip
            label={isClean ? "Clean" : "Not Clean"}
            color={isClean ? "success" : "error"}
            sx={{ px: 0.5, py: 0.5 }}
          />
          {/* <Chip
            label={`Accident Reported: ${data?.issues?.accident_damage}`}
            color={data?.issues?.accident_damage > 0 ? "error" : "default"}
            sx={{ px: 0.5, py: 0.5 }}
          /> */}
          <Chip
            label={`Accident Reported`}
            color={data?.accedent === true ? "error" : "default"}
            sx={{ px: 0.5, py: 0.5 }}
          />
          <Chip
            label={`Minor`}
            color={data?.minor === true ? "error" : "default"}
            sx={{ px: 0.5, py: 0.5 }}
          />
          <Chip
            label={`Moderate`}
            color={data?.moderate === true ? "error" : "default"}
            sx={{ px: 0.5, py: 0.5 }}
          />
          <Chip
            label={`Severe`}
            color={data?.severe === true ? "error" : "default"}
            sx={{ px: 0.5, py: 0.5 }}
          />
          {/* <Chip
            label={`Branded Title Check: ${
              data?.branded === true ? "Branded" : "Not Branded"
            }`}
            color={data?.branded === true ? "error" : "success"}
            sx={{ px: 0.5, py: 0.5 }}
          /> */}
          {/* <Chip
            label={`Airbag Deployed: ${data?.issues?.airbag}`}
            color={data?.issues?.airbag > 0 ? "error" : "default"}
            sx={{ px: 0.5, py: 0.5 }}
          />
          <Chip
            label={`Structural Damage: ${data?.issues?.structural_damage}`}
            color={data?.issues?.structural_damage > 0 ? "error" : "default"}
            sx={{ px: 0.5, py: 0.5 }}
          />
          <Chip
            label={`Total Loss: ${data?.issues?.total}`}
            color={data?.issues?.total > 0 ? "error" : "default"}
            sx={{ px: 0.5, py: 0.5 }}
          />
          <Chip
            label={`Previous Owners: ${data?.previous_owners}`}
            sx={{ px: 0.5, py: 0.5 }}
          />
          <Chip
            label={`Last Recorded Odometer: ${data?.odometer_last?.toLocaleString()}`}
            sx={{ px: 0.5, py: 0.5 }}
          /> */}
        </>
      )}
    </Stack>
  )
}
