import { useMemo } from "react"
import useAuth from "../../context/Auth"

export default function BidMadeBy({ by }) {
  const { user } = useAuth()

  const bidder = useMemo(() => {
    if (user?._id === by) return user?.fname
    return user?.selected?.users?.find((u) => u?._id === by)?.fname
  }, [user])
  return <>{bidder}</>
}
