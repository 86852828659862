import { Alert, Button, TextField } from "@mui/material"
import { Stack } from "@mui/material"
import axios from "axios"
import React from "react"
import { URL } from "../../config/apis"
import useAuth from "../../context/Auth"

const TextFieldInput = ({ label, value, handleChange, name }) => {
  return (
    <TextField
      size="small"
      id="outlined-basic"
      label={label}
      variant="outlined"
      fullWidth
      value={value}
      onChange={(e) => handleChange(e.target.value, name)}
    />
  )
}

export default function ProfileInfoPage() {
  const { user, setUser } = useAuth()
  const [dealer, setDealer] = React.useState({
    f_name: user?.f_name,
    l_name: user?.l_name,
    email: user?.email,
    phone: user?.phone,
  })
  const [loading, setLoading] = React.useState(false)
  const [success, setSucess] = React.useState(null)
  const [error, setError] = React.useState(null)

  const handleChange = (value, name) => {
    setDealer((prev) => ({ ...prev, [name]: value }))
  }

  const submitUpdate = async () => {
    setError(null)
    setSucess(null)
    setLoading(true)
    try {
      const res = await axios.put(`${URL}/users/update`, dealer, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      if (res.data.ok) {
        setUser((prev) => ({ ...prev, ...res.data.user }))
        setLoading(false)
        setSucess("Profile Updated succesfully")
      }
    } catch (error) {
      setError(error.response?.data?.message || error.message)
      setLoading(false)
      console.log(error)
    } finally {
      setTimeout(() => {
        setSucess(null)
      }, 2000)
    }
  }

  return (
    <Stack px={2} pt={3} pb={2} spacing={3}>
      <TextFieldInput
        label="First Name"
        value={dealer?.f_name}
        handleChange={handleChange}
        name="f_name"
      />
      <TextFieldInput
        label="Last Name"
        value={dealer?.l_name}
        handleChange={handleChange}
        name="l_name"
      />
      <TextFieldInput
        label="Email"
        value={dealer?.email}
        handleChange={handleChange}
        name="email"
      />
      <TextFieldInput
        label="Phone Number"
        value={dealer?.phone}
        handleChange={handleChange}
        name="phone"
      />
      <br />
      <Stack direction={"row"} justifyContent="end">
        <Button
          onClick={submitUpdate}
          disabled={loading}
          variant="contained"
          color="primary"
        >
          Update
        </Button>
      </Stack>
      {loading && <Alert severity="info">Updating Please wait ...</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
    </Stack>
  )
}
