import { Grid } from "@mui/material"
import React from "react"
import DealershipCard from "../components/dealershipCard"
import useAuth from "../context/Auth"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import Alert from "@mui/material/Alert"

export default function Dealerships() {
  const { user } = useAuth()
  const [error, setError] = React.useState()
  const [loading, setLoading] = React.useState(false)

  return (
    <>
      <div>
        {error && <Alert severity="error">{error}</Alert>}
        <div style={{ height: 10 }}></div>
        <Grid sx={{ px: "5px" }} container spacing={2}>
          {user?.dealerships?.map((d, i) => (
            <DealershipCard
              key={d?._id + i}
              item={d}
              setError={setError}
              setLoading={setLoading}
            />
          ))}
        </Grid>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={() => {}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}
