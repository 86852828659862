import * as React from "react"
import CssBaseline from "@mui/material/CssBaseline"
import Container from "@mui/material/Container"
import axios from "axios"
import { URL } from "../config/apis"
import { useEffect } from "react"
import LoadingPage from "../components/global/LoadingPage"
import AppointmentTable from "../components/appointments/AppointmentTable"
import ErrorState from "../components/global/ErrorState"

export default function AppointmentsPage() {
  const [loading, setLoading] = React.useState(true)
  const [appointments, setAppointments] = React.useState([])
  const [err, setErr] = React.useState(null)

  const getLeads = async () => {
    setErr(null)
    try {
      const res = await axios.get(`${URL}/calendar`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      setAppointments(res.data)
    } catch (error) {
      setErr(error.response?.data?.message || error.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    getLeads()
  }, [])

  if (loading) {
    return <LoadingPage />
  }

  if (err) {
    return <ErrorState message={err} />
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        maxWidth="100%"
        sx={{
          flex: 1,
          height: "100%",
          flexDirection: "column",
          display: "flex",
          width: "100%",
        }}
      >
        <AppointmentTable appointments={appointments} />
      </Container>
    </React.Fragment>
  )
}
