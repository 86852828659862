import * as React from "react"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { Grid } from "@mui/material"
import useAuth from "../../context/Auth"
import { URL } from "../../config/apis"
import axios from "axios"

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
)

export default function DealershipCard({ item, setLoading, setError }) {
  const { selected, setSelected } = useAuth()

  const getCurrentUser = async () => {
    setError(null)
    setLoading(true)
    try {
      const res = await axios.patch(
        `${URL}/users/select`,
        { sid: item?._id },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      setSelected(item?._id)
      setTimeout(() => {
        sessionStorage.clear()
        window.location.reload(true)
      }, 1000)
    } catch (error) {
      setError(error.response?.data?.message || error.message)
      setLoading(false)
    }
  }

  return (
    <Grid item sm={2}>
      <Card sx={{ width: "100%", opacity: item?.isActive ? 1 : 0.6 }}>
        <CardContent>
          <Typography variant="h5" component="div">
            {item?.dname}
          </Typography>
          <Typography color="text.secondary">{`${item?.city}/${item?.state}`}</Typography>
        </CardContent>
        <CardActions>
          <Button
            onClick={getCurrentUser}
            fullWidth
            color={selected === item?._id ? "success" : "info"}
            variant="contained"
            size="small"
            disabled={selected === item?._id || !item?.isActive}
          >
            {selected === item?._id
              ? "Selected"
              : !item?.isActive
              ? "Inactive"
              : "Access"}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  )
}
