import * as React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { Alert, Stack, Typography } from "@mui/material"
import useAuth from "../../context/Auth"
import axios from "axios"
import { URL } from "../../config/apis"
import { formatPrice } from "../../helppers/formatters"

export default function AgreementModel() {
  const { user } = useAuth()
  const [loading, setLoading] = React.useState(null)
  const [err, setErr] = React.useState(null)

  const handleAgreed = async () => {
    setErr(null)
    setLoading(true)
    try {
      const res = await axios.patch(
        `${URL}/dealerships/agree`,
        { id: user?.selected?._id },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      setTimeout(() => {
        window.location.reload()
      }, 500)
    } catch (error) {
      setErr(error.response?.data?.message || error.message)
      setLoading(false)
    }
  }

  return (
    <div>
      <Dialog
        open={true}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Stack alignItems={"center"} spacing={2}>
            <img
              src="/dealer-bids-logo-blue.svg"
              alt={`DealerBids`}
              style={{ width: "100%", maxWidth: "120px" }}
            />
            <Typography fontWeight={"700"} variant="h4">
              Dealership Agreement
            </Typography>
            <Typography textAlign={"center"} variant="body1">
              This agreement is between <b>MC2 Ventures LLC</b>, the owner of{" "}
              <b>DealerBids</b>, and <b>{user?.selected?.dname}</b>.
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Stack spacing={1} color="black">
              <Typography variant="body1">
                <b>Product/Service</b>: DealerBids.
              </Typography>
              <Typography variant="body1">
                <b>Description</b>: DealerBids is a software technology and
                marketing platform that aggregates private party used vehicle
                listings for sale into the DealerBids dealership portal. All
                listings displayed include vehicle year, make, model, VIN,
                mileage, pictures, price, and estimated condition as supplied by
                the listing parties. Dealerships can choose to accept the listed
                price, negotiate with offers and counteroffers, or decline the
                listed vehicle.
              </Typography>
              <Typography variant="body1">
                Once an offer is accepted, DealerBids personnel work to schedule
                an in-person inspection appointment at the participating
                dealership's location. Confirmed appointments are displayed in
                the DealerBids dealership portal.
              </Typography>
              <Typography variant="body1">
                Once a physical inspection is completed, it is the dealership's
                responsibility to verify every aspect of the vehicle including
                its title status, condition, and value and to finalize the
                purchase of the private seller's vehicle.
              </Typography>
              <Typography variant="body1">
                <b>Disclaimer</b>: Please note that M2C Ventures LLC/DealerBids
                is not a wholesaler and does not own the vehicles listed on its
                website or dealership portal.
              </Typography>
              {/* <Typography variant="body1">
                <b>Pricing</b>: Our fee is{" "}
                {formatPrice(user?.selected?.fee || 699)} per vehicle acquired
                through our platform.
              </Typography> */}
              <Typography variant="body1">
                <b>Term</b>: Our service has no term and may be canceled at any
                time without penalty.
              </Typography>
            </Stack>
            {err && (
              <>
                <br />
                <Alert color="error">{err}</Alert>
              </>
            )}
            {loading && (
              <>
                <br />
                <Alert color="info">Please wait ...</Alert>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleAgreed} disabled={loading}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
