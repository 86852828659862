import moment from "moment"
import momentTZ from "moment-timezone"

export const formatPrice = (price) => {
  if (!price || isNaN(price)) return "N/A"
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  })
  return formatter.format(price)
}

export const formatDate = (date) => {
  return moment(date).format("MM/DD/YYYY")
}

export const formatDateTz = (date, tz) => {
  return momentTZ.tz(date, tz).format("MM/DD/YYYY @ h:mm A")
}


export const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) return "N/A"
  if (phoneNumberString?.length < 11) {
    phoneNumberString = "1" + phoneNumberString
  }
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "")
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = match[1] ? "+1 " : ""
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("")
  }
  return null
}