import * as React from "react"
import CssBaseline from "@mui/material/CssBaseline"
import Container from "@mui/material/Container"
import axios from "axios"
import { URL } from "../config/apis"
import { useEffect } from "react"
import LoadingPage from "../components/global/LoadingPage"
import DealsTable from "../components/deals/DealsTable"
import { Pagination, Stack } from "@mui/material"
import ErrorState from "../components/global/ErrorState"

const pageSize = 20

export default function DealsPage() {
  const cash = JSON.parse(sessionStorage.getItem("deals"))

  const [loading, setLoading] = React.useState(true)
  const [deals, setDeals] = React.useState([])
  const [err, setErr] = React.useState(null)

  const [count, setCount] = React.useState(0)

  const [page, setPage] = React.useState(cash?.page || 1)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const getLeads = async () => {
    setErr(null)
    try {
      const res = await axios.get(`${URL}/leads/deals?page=${page}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      setDeals(res.data.leads)
      setCount(res.data.count)
    } catch (error) {
      setErr(error.response?.data?.message || error.message)
    }
    setLoading(false)
  }

  const totalPages = React.useMemo(() => {
    return Math.ceil(count / pageSize)
  }, [count, pageSize])

  useEffect(() => {
    getLeads()

    sessionStorage.setItem("deals", JSON.stringify({ page }))
  }, [page])

  if (loading) {
    return <LoadingPage />
  }

  if (err) {
    return <ErrorState message={err} />
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        maxWidth="100%"
        sx={{
          flex: 1,
          height: "100%",
          flexDirection: "column",
          display: "flex",
          width: "100%",
        }}
      >
        <DealsTable deals={deals} />
        <br />
        {totalPages > 1 && (
          <Stack
            direction={"row"}
            justifyContent="center"
            sx={{ mt: 2 }}
            spacing={2}
          >
            <Pagination
              page={page}
              onChange={handleChangePage}
              count={totalPages}
              showFirstButton
              showLastButton
            />
          </Stack>
        )}
      </Container>
    </React.Fragment>
  )
}
